import { createSlice } from '@reduxjs/toolkit';
import { Timestamp } from 'firebase/firestore';
import {
  DashboardState,
  IconType,
  ServiceTypesAndDesc,
  SnowEventDetailsProps,
  SnowEventPageTypes,
  crewMemberPageTypes,
  estimateLineItemStateTypes,
  newServiceDetailsTypesFromProfilePage,
  routesTypes,
} from '../../@types/dashboard';
import { dispatch } from '../store';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
TimeAgo.addDefaultLocale(en);

// When the service type is added to the business, it also needs to have an iconImage url,
// When adding a new servicem, add the icon URL here too: ServiceAddressCard()
const initalStateForBussinessServices = [
  {
    id: '01',
    serviceType: 'Weekly Lawn Service',
    serviceDescription:
      'Service includes lawn mowing, string trimming, edging, and blowing debris left on or around the property. Service is done on the same day of every week, weather permitting. Each service starts with a thorough walkthrough of the property, checking for any obstructions or items that could damage our equipment or your property.',
    isRecurring: true,
    configs: {
      isSelectedForEstimateFromClientProfile: false,
    },
    icon: null,
  },
  {
    id: '07',
    serviceType: 'Spring Clean Up & Lawn Dethatching',
    serviceDescription:
      'One-time service includes the removal of leaves, branches, and debris from lawns, the base of trees, and garden beds. Remove dead and broken plants to allow for spring blossom. Our machines slice through accumulated organic matter known as thatch at the base of grass plants, where stems meet roots and soil. Thatch layers of 1 inch or more become barriers instead of benefits. Thick thatch blocks water and fertilizer, and grassroots get trapped in thatch, vulnerable to heat, drought, and stress. Water from irrigation can also accumulate in the thatch layer, so grassroots suffocate from lack of air. After removing the thatch layer, we collect the debris and compost them offsite.',
    isRecurring: false,
    configs: {
      isSelectedForEstimateFromClientProfile: false,
    },
    icon: null,
  },
  {
    id: '05',
    serviceType: 'Core/Plug Aerating',
    serviceDescription:
      'Our machines cut little holes and remove “cores” or plugs of dirt from your soil through hollow tines. These plugs are usually about 2-3 inches deep. We leave the cores to decompose, adding nutrients to the soil to promote grass growth.',
    isRecurring: false,
    configs: {
      isSelectedForEstimateFromClientProfile: false,
    },
    icon: null,
  },
  {
    id: '15',
    serviceType: 'Outdoor Essentials',
    serviceDescription:
      'Weekly lawn maintenance including lawn mowing, edging, and string trimming. Plus Spring and Fall Clean up and detacher with disposal and seasonal aerator. Season service between April 1st to November 31st at a monthly fee.',
    isRecurring: true,
    configs: {
      isSelectedForEstimateFromClientProfile: false,
    },
    icon: null,
  },
  {
    id: '18',
    serviceType: 'Fall Clean Up',
    serviceDescription:
      'One-time service includes the removal of leaves, branches, and debris from lawns, the base of trees, and garden beds. Remove dead and broken plants to allow prep for the winter.',
    isRecurring: false,
    configs: {
      isSelectedForEstimateFromClientProfile: false,
    },
    icon: null,
  },
  {
    id: '02',
    serviceType: 'Bi-Weekly Lawn Service',
    serviceDescription:
      'Service includes lawn mowing, string trimming, and blowing debris left on or around the property. Service is done on the same day every other week, weather permitting. Each service starts with a thorough walkthrough of the property, checking for any obstructions or items that could damage our equipment or your property.',
    isRecurring: true,
    configs: {
      isSelectedForEstimateFromClientProfile: false,
    },
    icon: null,
  },
  {
    id: '03',
    serviceType: 'Weed Control and Fertilization',
    serviceDescription:
      'Should update the serviceDescription, but just testing  isRecurring function',
    isRecurring: false,
    configs: {
      isSelectedForEstimateFromClientProfile: false,
    },
    icon: null,
  },
  {
    id: '04',
    serviceType: 'Lawn Dethatching',
    serviceDescription:
      'Our machines slice through accumulated organic matter known as thatch at the base of grass plants, where stems meet roots and soil. Thatch layers of 1 inch or more become barriers instead of benefits. Thick thatch blocks water and fertilizer, and grassroots get trapped in thatch, vulnerable to heat, drought, and stress. Water from irrigation can also accumulate in the thatch layer, so grassroots suffocate from lack of air. After removing the thatch layer, we collect the debris and compost them offsite.',
    isRecurring: false,
    configs: {
      isSelectedForEstimateFromClientProfile: false,
    },
    icon: null,
  },

  {
    id: '08',
    serviceType: 'Broadcast Seeding',
    serviceDescription:
      'Overseeding is the planting of grass seed directly into existing turf, without tearing up the turf, or the soil. It’s an easy way to fill in bare spots, improve the density of turf, establish improved grass varieties and enhance your lawn’s color.',
    isRecurring: false,
    configs: {
      isSelectedForEstimateFromClientProfile: false,
    },
    icon: null,
  },
  {
    id: '10',
    serviceType: 'Lawn bed maintenance ',
    serviceDescription:
      'Bed maintenance includes the proper and healthy trimming of plants to their recommended heights and lengths. This ensures proper and healthy growth all season long. Removal of weeds, dead plant material, leaves, sticks and over growth to provide a nice clean appearance. See notes for service scope breakdown.',
    isRecurring: false,
    configs: {
      isSelectedForEstimateFromClientProfile: false,
    },
    icon: null,
  },
  {
    id: '11',
    serviceType: 'Pruning',
    serviceDescription:
      'Pruning simply refers to the process of chopping off dead and decayed leaves from the plants. Pruning or trimming is a momentous service that needs to be performed during the correct time of the year depending upon the type of plant. This allows the plans to bloom by eliminating dried and strayed parts. See notes for service scope breakdown.',
    isRecurring: false,
    configs: {
      isSelectedForEstimateFromClientProfile: false,
    },
    icon: null,
  },
  {
    id: '12',
    serviceType: 'Hedging',
    serviceDescription:
      'Hedging refers to trimming down a plant in a geometrical shape. This gives an appealing look to the yard. See notes for service scope breakdown.',
    isRecurring: false,
    configs: {
      isSelectedForEstimateFromClientProfile: false,
    },
    icon: null,
  },
  {
    id: '13',
    serviceType: 'Mulching',
    serviceDescription:
      'Mulching is not just for a pleasant view, it has some pragmatic benefits of water retention among the plants via root insulation and weed control. See notes for service scope breakdown. ',
    isRecurring: false,
    configs: {
      isSelectedForEstimateFromClientProfile: false,
    },
    icon: null,
  },
  {
    id: '14',
    serviceType: 'Landscaping Service',
    serviceDescription: 'See notes for service scope breakdown. ',
    isRecurring: false,
    configs: {
      isSelectedForEstimateFromClientProfile: false,
    },
    icon: null,
  },
  {
    id: '16',
    serviceType: 'Outdoor Oasis',
    serviceDescription:
      'All services from Outdoor Essentials plus seasonal fertilization and pre-herbicide control. Season service between April 1st to November 31st at a monthly fee.',
    isRecurring: true,
    configs: {
      isSelectedForEstimateFromClientProfile: false,
    },
    icon: null,
  },
  {
    id: '17',
    serviceType: 'Outdoor Eden',
    serviceDescription:
      'All services from Outdoor Essentials and Outdoor Oasis plus shrub prunning, flower bed maintenance & mulching. Season service between April 1st to November 31st at a monthly fee.',
    isRecurring: true,
    configs: {
      isSelectedForEstimateFromClientProfile: false,
    },
    icon: null,
  },
  {
    id: '19',
    serviceType: 'Snow Blowing',
    serviceDescription: 'DannysRoute dot come',
    isRecurring: true,
    configs: {
      isSelectedForEstimateFromClientProfile: false,
    },
    icon: IconType.SNOWFLAKE,
  },
  {
    id: '20',
    serviceType: 'Snow Removal',
    serviceDescription: 'PeralesLawnService dot come',
    isRecurring: true,
    configs: {
      isSelectedForEstimateFromClientProfile: false,
    },
    icon: null,
  },
];

const initialState: DashboardState = {
  backdropOpen: false,
  isLoading: false,
  error: null,
  events: [],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
  clientMessages: [],
  clientDetails: [],
  clientContactDialogState: {
    isOpen: false,
    isNotifyButtonLoading: false,
    name: '',
    email: '',
    message: '',
    notifyClientResponse: null,
  },
  clientMessagesLoaded: false,
  clientListLoaded: false,
  estimateListLoaded: false,
  clientOpenProfile: {
    uid: null,
    firstName: null,
    lastName: null,
    isActive: false,
    billingAddress: null,
    billingCity: null,
    billingState: 'STATE NOT PASSED',
    billingZip: null,
    contactEmail: null,
    contactPhone: null,
    serviceAddress: [],
    completedServiceList: [],
    invoice: [],
    serviceEditDialogState: {
      isOpen: false,
      editIndex: 0,
      sericeType: null,
      serviceDescription: null,
      servicePrice: null,
      serviceDay: null,
      serviceAddress: null,
      isActive: false,
      isRecurring: false,
    },
    invoiceDetails: {
      nextDueDate: '',
      openBalance: 0,
      preAuthorizedAmount: 0,
    },
    selectServiceDateState: {
      isOpen: false,
      serviceAddressIndex: 0,
    },
    estimates: [],
  },
  tenantId: '',
  hasTenantSetUpStripe: false,
  clientLoaded: false,
  tenantIdLoaded: false,
  newEstimateForm: {
    estimateId: null,
    clientId: '',
    clientDetails: {
      firstName: 'Jose',
      lastName: 'perales',
      contactEmail: 'jose@mail.com',
      contactPhone: '612-508-8822',
    },
    serviceAddressDetails: {
      serviceAddressId: 'oisahdfiohsdiof',
      serviceAddress: '3901 8th Lane',
      serviceCity: 'Anoka',
      serviceZip: '55303',
      serviceState: 'MN',
    },
    estimatePageDetails: {
      clientView: false,
      status: 'Pending', //Created, sent Pending, Accepted, Rejected, completed
      clientApproved: false,
      sentToClient: false,
      estimateDueDate: 'Monday April 1st, 2022',
      serviceDate: null,
      dateRequested: '"November 21, 2022 at 3:58:32 PM UTC-5"',
      totalPrice: null,
    },
    totalItems: 0,
    lineItems: [],

    //CREATED -- Created the estimate, NOTIFICATION -- Any notification for the client, VIEWED -- WHen the client viewed UPDATED -- If we updated ,
    timeline: [
      {
        timestamp: '03/04/21 12:00pm',
        action: 'CREATED',
        comment: 'Estimate Created', //CREATED -- Created the estimate, NOTIFICATION -- Any notification for the client, VIEWED -- WHen the client viewed UPDATED -- If we updated ,
      },
      {
        timestamp: '03/04/21 12:05pm',
        action: 'NOTIFICATION',
        comment: 'Estimate sent to Josepablopt@gmail.com', // Any updated done to the estimate should be logged as 'UPDATE' since we already sent it to the client.
      },

      {
        timestamp: '03/04/21 12:10pm',
        action: 'CUSTOMER_ACTION',
        comment: 'Client viewed estimate',
      },
      {
        timestamp: '03/04/21 12:15pm',
        action: 'CUSTOMER_COMMENT',
        comment: 'This looks good!',
      },
      {
        timestamp: '03/04/21 12:16pm',
        action: 'CUSTOMER_ACTION',
        comment: 'Customer accepcted estimate',
      },
    ],
  },
  clientList: [],
  estimateList: [],
  todaysTodos: [],
  pendingSchedlingList: [],
  todaysDetails: {
    projectedRevenue: 0,
    totalRevenue: 0,
  },
  serviceCompletedDialog: {
    isOpen: false,
    clientName: null,
    contactPhone: null,
    clientUid: null,
    clientPrice: null,
    clientAddress: null,
    clientServiceDate: null,
    serviceDay: null,
    scheduledService: null,
    serviceDescription: null,
  },
  businessServices: initalStateForBussinessServices,
  newServicePage: {
    step: 0,
    dialogSettings: {
      isOpen: false,
    },
    serviceAddress: null,
    calendarDetails: {
      isOpen: false,
      selectedDate: new Date().toDateString(),
      isTodaySelected: true,
    },
    details: {
      id: null,
      serviceType: null,
      serviceDescription: null,
      serivcePrice: '0.0',
      date: null,
    },

    // we need a client list, but we should follow good pratcie and not replicate the data.
  },
  pageEstimateDetails: {
    id: '',
    clientUid: null,
    clientViewed: null,
    contactEmail: null,
    contactPhone: null,
    dateRequested: '',
    dateScheduled: null,
    name: null,
    requestList: null,
    sent: false,
    status: {
      activeStep: 0,
      clientInitalView: false,
      notifications: {
        client: false,
        tenant: false,
      },
      sentToClientForReview: false,
      stage: null,
    },
    comments: null,
    summaryCard: {
      totalEstimateDue: 0,
    },
    zoneNoteDialog: {
      isOpen: false,
      openImageDetails: {
        isOpen: false,
        details: null,
      },
      urlToImage: '',
      requestListIndex: 0,
      isSaveBtnLoading: false,
      note: '',
      firestoreBucketRef: null,
    },
    priceDialog: {
      isOpen: false,
      requestListIndex: 0,
      isSaveBtnLoading: false,
    },
    serviceDateDialog: {
      isOpen: false,
      isSaveBtnLoading: false,
    },
    scheduleAcceptedServices: {
      isEnabled: false,
    },
  },
  tenantCrewMembersPage: {
    stagingCrewMemberDetails: {
      companyMark: 40,
    },
    crewMembers: null,
    crewMembersLoaded: false,
    sectionView: 'LANDING',
  },
  saveForMananaSessions: {
    fetched: false,
    sessions: null,
    dialogDetails: {
      isOpen: false,
      details: null,
    },
  },
  routesSnow: {
    fetched: false,
    pendingClients: null,
    routesFetched: false,
    routes: null,
    clientUpdateDialog: {
      isOpen: false,
      shouldAckInSnowRoute: false,
      routeSelectedValue: 'anoka',
      routeSelectedFirebaseID: null,
      currentRouteFirebaseUid: null,
      isSnowMarked: false,
      firebaseUid: null,
      title: null,
      subtitle: null,
      clientFirebaseUid: null,
    },
    snowEventData: {
      isProjectedDateAndTimeOpen: false,
      projectedTotalAccumulation: 1.5,
      projectedStartDateAndTime: null,
    },
  },
  snowEventPage: null,
  snowRouteDialogConfig: {
    isDialogOpen: false,
    documentId: null,
    dispatched: false,
    sessionData: {
      assignedCrewMembers: null,
      clientDetails: null,
      routeSnapshot: {
        name: null,
        type: null,
      },
    },
    completedPercentage: null,
    clientDetails: {
      isDialogOpen: false,
      clientDetailsIndex: 0,
      activeRouteIndex: 0,
    },
  },
  snowEventsArray: null,
  routes: null,
  routeDialog: {
    isOpen: false,
    isLoading: false,
  },
  selectedServices: [],
};

const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    resetBusinessServicesState(state) {
      state.businessServices = initalStateForBussinessServices;
    },
    setTenantSnowEventsToState(state, action) {
      state.snowEventsArray = action.payload;
    },
    setAndOpenClientRouteDetails(state, action) {
      state.snowRouteDialogConfig.clientDetails.isDialogOpen = action.payload.isDialogOpen;
      state.snowRouteDialogConfig.clientDetails.clientDetailsIndex =
        action.payload.clientDetailsIndex;
    },
    setSnowEventRouteDialogDataState(state, action) {
      state.snowRouteDialogConfig.completedPercentage =
        action.payload.snowEvent.completedPercentage;
      state.snowRouteDialogConfig.documentId = action.payload.snowEvent.documentId;
      state.snowRouteDialogConfig.dispatched = action.payload.snowEvent.dispatched;
      state.snowRouteDialogConfig.sessionData = action.payload.snowEvent.sessionData;
      state.snowRouteDialogConfig.clientDetails.activeRouteIndex = action.payload.routeIndex;
    },

    setSnowRouteDialogOpenState(state, action) {
      state.snowRouteDialogConfig.isDialogOpen = action.payload;
    },

    setSnowEventPageState(state, action) {
      state.snowEventPage = action.payload;
    },
    updateProjectedEventTimeAndDateState(state, action) {
      state.routesSnow.snowEventData.projectedStartDateAndTime = action.payload;
    },

    setProjectedTotalAccumulationDialogState(state, action) {
      state.routesSnow.snowEventData.isProjectedDateAndTimeOpen = action.payload;
    },

    updateProjectedTotalAccumulation(state, action) {
      state.routesSnow.snowEventData.projectedTotalAccumulation = action.payload;
    },

    updateRoutesStateWithNewValues(state, action) {
      state.routesSnow.routes = action.payload;
    },

    setTenantRoutes(state, action) {
      state.routesSnow.routes = action.payload;
      state.routesSnow.routesFetched = true;
    },
    setReduxStateForSnowMarkersInstalled(state, action) {
      state.routesSnow.clientUpdateDialog.isSnowMarked = action.payload;
    },

    setReduxStateForSnowRoute(state, action) {
      state.routesSnow.clientUpdateDialog.routeSelectedValue = action.payload.nameValue;
      state.routesSnow.clientUpdateDialog.routeSelectedFirebaseID = action.payload.firebaseId;
    },

    setReduxStateForSnowDialog(state, action) {
      state.routesSnow.clientUpdateDialog.isOpen = action.payload;
    },

    setReduxStateForSnowDetailsDialog(state, action) {
      const clientFirebaseUid = action.payload.firebaseUid
        ? action.payload.firebaseUid
        : action.payload.clientFirebaseUid;

      state.routesSnow.clientUpdateDialog.firebaseUid = clientFirebaseUid;
      state.routesSnow.clientUpdateDialog.title = action.payload.title;
      state.routesSnow.clientUpdateDialog.subtitle = action.payload.subtitle;
      state.routesSnow.clientUpdateDialog.clientFirebaseUid = clientFirebaseUid;
      state.routesSnow.clientUpdateDialog.currentRouteFirebaseUid =
        action.payload.currentRouteFirebaseUid;
    },

    setReduxStateForSnowSnapshots(state, action) {
      state.routesSnow.pendingClients = action.payload;
      state.routesSnow.fetched = true;
    },

    setReduxStateForSFMananaSessionDialog(state, action) {
      state.saveForMananaSessions.dialogDetails.isOpen = action.payload;
    },

    setReduxStateForSFMananaSessionOnClick(state, action) {
      state.saveForMananaSessions.dialogDetails.details = action.payload;
    },
    setReduxStateForSFMananaSessions(state, action) {
      state.saveForMananaSessions.sessions = action.payload;
      state.saveForMananaSessions.fetched = true;
    },

    setReduxStateForSnowRoutes(state, action) {
      state.saveForMananaSessions.sessions = action.payload;
      state.saveForMananaSessions.fetched = true;
    },

    // START LOADING
    backdropStateTrue(state) {
      state.backdropOpen = true;
    },

    backdropStateFalse(state) {
      state.backdropOpen = false;
    },

    startLoading(state) {
      state.isLoading = true;
    },
    endLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    tenantHasCompletedStripeSetUp(state) {
      state.hasTenantSetUpStripe = true;
    },

    // GET EVENTS
    getEventsSuccess(state, action) {
      state.isLoading = false;
      state.events = action.payload;
    },

    // CREATE EVENT
    createEventSuccess(state, action) {
      const newEvent = action.payload;
      state.isLoading = false;
      state.events = [...state.events, newEvent];
    },

    // UPDATE EVENT
    updateEventSuccess(state, action) {
      const event = action.payload;
      const updateEvent = state.events.map((_event) => {
        if (_event.id === event.id) {
          return event;
        }
        return _event;
      });

      state.isLoading = false;
      state.events = updateEvent;
    },

    // DELETE EVENT
    deleteEventSuccess(state, action) {
      const { eventId } = action.payload;
      const deleteEvent = state.events.filter((event) => event.id !== eventId);
      state.events = deleteEvent;
    },

    // SELECT EVENT
    selectEvent(state, action) {
      const eventId = action.payload;
      state.isOpenModal = true;
      state.selectedEventId = eventId;
    },

    // SELECT RANGE
    selectRange(state, action) {
      const { start, end } = action.payload;
      state.isOpenModal = true;
      state.selectedRange = { start, end };
    },

    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    },

    setEstimateLineItem(state, action) {
      const newEvent = action.payload;

      const saveDetails = {
        ...newEvent,
        id: state.newEstimateForm.totalItems + 1,
      };

      state.newEstimateForm.lineItems = [...state.newEstimateForm.lineItems, saveDetails];
      state.newEstimateForm.totalItems = state.newEstimateForm.totalItems + 1;
    },

    removeEstimateLineItem(state, action) {
      const newEvent = action.payload;
      state.newEstimateForm.lineItems = [...newEvent];
      state.newEstimateForm.totalItems = state.newEstimateForm.totalItems - 1;
    },

    setClientProfileServiceAddress(state, action) {
      state.clientOpenProfile.serviceAddress = action.payload;
    },

    setEstimateLineItemsNull(state) {
      state.newEstimateForm.lineItems = [];
      state.newEstimateForm.totalItems = 0;
    },

    setInvoiceDetails(state, action) {
      const addressDetails = action.payload;
      state.clientOpenProfile.invoice = addressDetails;
    },

    setServiceAddressDetails(state, action) {
      const addressDetails = action.payload;
      state.clientOpenProfile.uid = addressDetails.clientUid;
      state.clientOpenProfile.serviceAddress = [
        ...state.clientOpenProfile.serviceAddress,
        addressDetails,
      ];
    },

    setClientList(state, action) {
      const newEvent = action.payload;
      state.clientList = newEvent;
    },

    clearClientProfile(state) {
      state.clientOpenProfile.firstName = null;
      state.clientOpenProfile.lastName = null;
      state.clientOpenProfile.isActive = false;
      state.clientOpenProfile.uid = null;
      state.clientOpenProfile.invoice = [];
      state.clientOpenProfile.serviceAddress = [];
      state.clientOpenProfile.estimates = [];
    },

    clearEstimateDetails(state) {
      state.clientOpenProfile.firstName = null;
      state.clientOpenProfile.lastName = null;
      state.clientOpenProfile.isActive = false;
      state.clientOpenProfile.uid = null;
      state.clientOpenProfile.invoice = [];
      state.clientOpenProfile.serviceAddress = [];
    },
    //PERALES: FILL out the profile state from here:
    setClientProfile(state, action) {
      const newEvent = action.payload;
      const isBackWardsCompatibaleCheckForClientInvoiceDetails =
        newEvent.clientInvoiceDetails != null;
      state.clientOpenProfile.uid = newEvent.clientUid;
      state.clientOpenProfile.firstName = newEvent.firstName;
      state.clientOpenProfile.lastName = newEvent.lastName;
      state.clientOpenProfile.isActive = newEvent.active;
      state.clientOpenProfile.contactEmail = newEvent.contactEmail;
      state.clientOpenProfile.contactPhone = newEvent.contactPhone;
      state.clientOpenProfile.billingAddress = newEvent.billingAddress;
      state.clientOpenProfile.billingCity = newEvent.billingCity;
      state.clientOpenProfile.billingState = newEvent.billingState;
      state.clientOpenProfile.billingZip = newEvent.billingZip;
      state.clientOpenProfile.serviceAddress = newEvent.serviceAddresses;
      state.clientOpenProfile.completedServiceList = newEvent.completedServiceList;

      //PERALES INVOICE STUFF HERE!!
      if (isBackWardsCompatibaleCheckForClientInvoiceDetails) {
        state.clientOpenProfile.invoiceDetails.nextDueDate =
          newEvent.clientInvoiceDetails.nextDueDate;
        state.clientOpenProfile.invoiceDetails.preAuthorizedAmount =
          newEvent.clientInvoiceDetails.preAuthorizedAmount;
        state.clientOpenProfile.invoiceDetails.openBalance =
          newEvent.clientInvoiceDetails.openBalance;
      }
      // NEW THing
      if (newEvent.balanceDueInPennies != 0) {
        state.clientOpenProfile.invoiceDetails.openBalance = newEvent.balanceDueInPennies / 100;
      }

      // Estimate Page detail here:
      state.clientOpenProfile.estimates = newEvent.clientEstimates;
    },

    setEstimateDetails(state, action) {
      const newEvent = action.payload;
      const timeObj = new Timestamp(
        newEvent.dateRequested.seconds,
        newEvent.dateRequested.nanoseconds
      );

      // Checking to see if there is a date already set, if there is then set it to the redux state. else just keep it null
      if (newEvent.dateScheduled != null) {
        const timeObj2 = new Timestamp(
          newEvent.dateScheduled.seconds,
          newEvent.dateScheduled.nanoseconds
        );
        const dateScheduledValue = timeObj2.toDate().toDateString();
        state.pageEstimateDetails.dateScheduled = dateScheduledValue;
      }

      const dateRequested = timeObj.toDate().toDateString();
      // Iterating list of services to see if we should show the button to schedule them.
      const STATUS_ACCEPTED = 'ACCEPTED';
      const ESTIMATE_ALREADY_SCEHDULED = 'SCHEDULED';
      var shouldEnableButton = false;

      newEvent.requestList.forEach(function (service: any) {
        if (
          service.status === STATUS_ACCEPTED &&
          newEvent.status.stage != ESTIMATE_ALREADY_SCEHDULED
        ) {
          shouldEnableButton = true;
        }
      });

      state.pageEstimateDetails.id = newEvent.estimateUid;
      state.pageEstimateDetails.clientUid = newEvent.clientUid;
      state.pageEstimateDetails.clientViewed = newEvent.clientViewed;
      state.pageEstimateDetails.contactEmail = newEvent.contactEmail;
      state.pageEstimateDetails.contactPhone = newEvent.contactPhone;
      state.pageEstimateDetails.dateRequested = dateRequested;
      state.pageEstimateDetails.name = newEvent.name;
      state.pageEstimateDetails.requestList = newEvent.requestList;
      state.pageEstimateDetails.sent = newEvent.sent;
      state.pageEstimateDetails.status = newEvent.status;
      state.pageEstimateDetails.comments = newEvent.comments;
      state.pageEstimateDetails.scheduleAcceptedServices.isEnabled = shouldEnableButton;
    },

    updateEstimateStatusStep(state) {
      state.pageEstimateDetails.status.activeStep = state.pageEstimateDetails.status.activeStep + 1;
      state.pageEstimateDetails.status.clientInitalView = false;
      state.pageEstimateDetails.status.notifications.client = false;
      state.pageEstimateDetails.status.notifications.tenant = false;
      state.pageEstimateDetails.status.sentToClientForReview = true;
      state.pageEstimateDetails.status.stage = 'PENDING';
    },

    setEstimatePageDetails(state, action) {
      const event = action.payload;
      state.newEstimateForm.estimatePageDetails = [state.estimateList[event]];
      state.newEstimateForm.estimateId = state.newEstimateForm.estimatePageDetails[0].id;
      state.newEstimateForm.clientId = state.newEstimateForm.estimatePageDetails[0].clientUid;
    },

    setEstimatePageDialogViewState(state, action) {
      const lineItem = action.payload;
      const lineIndex =
        lineItem.requestListIndex === null
          ? state.pageEstimateDetails.priceDialog.requestListIndex
          : lineItem.requestListIndex;

      //check if isOpen coming in as false, if it is, then we should clear the whole state?
      state.pageEstimateDetails.priceDialog.isOpen = lineItem.isOpen;
      state.pageEstimateDetails.priceDialog.requestListIndex = lineIndex;
    },

    setEstimatePageZoneDetailsDialogViewState(state, action) {
      const lineItem = action.payload;
      const lineIndex =
        lineItem.requestListIndex === null
          ? state.pageEstimateDetails.zoneNoteDialog.requestListIndex
          : lineItem.requestListIndex;

      //check if isOpen coming in as false, if it is, then we should clear the whole state?
      state.pageEstimateDetails.zoneNoteDialog.isOpen = lineItem.isOpen;
      state.pageEstimateDetails.zoneNoteDialog.requestListIndex = lineIndex;
    },

    setEstimatePageDialogServiceDateViewState(state, action) {
      state.pageEstimateDetails.serviceDateDialog.isOpen = action.payload;
    },

    setEstimatePageSaveBtnDialogState(state, action) {
      //check if isOpen coming in as false, if it is, then we should clear the whole state?
      state.pageEstimateDetails.priceDialog.isSaveBtnLoading = action.payload;
    },

    setUpdatePriceToLineEstimate(state, action) {
      var totalLineItemPrice = 0;
      state.pageEstimateDetails.requestList &&
        state.pageEstimateDetails.requestList.forEach((element: any) => {
          const handelingNullValue =
            element.price == undefined || element.price == null ? 0 : element.price;
          totalLineItemPrice = totalLineItemPrice + handelingNullValue;
        });

      //Updating State:
      if (state.pageEstimateDetails.requestList != null) {
        state.pageEstimateDetails.requestList[
          state.pageEstimateDetails.priceDialog.requestListIndex
        ] = action.payload;
      }
      state.pageEstimateDetails.summaryCard.totalEstimateDue = totalLineItemPrice;
    },

    updateEstimateComments(state, action) {
      const newComments = action.payload;
      state.pageEstimateDetails.comments = newComments;
    },

    setDateScheduledToEstimate(state, action) {
      state.pageEstimateDetails.dateScheduled = action.payload;
    },

    setEstimatePageAfterFBSave(state, action) {
      const event = action.payload;
      const estimateListDetails = {
        uid: event.estimateUid,
        clientUid: event.clientUid,
        dateRequested: event.dateRequested,
        sent: false,
        status: event.status,
        name: event.name,
      };

      state.estimateList.push(estimateListDetails);

      state.pageEstimateDetails.id = event.estimateUid;
      state.pageEstimateDetails.clientUid = event.clientUid;
      state.pageEstimateDetails.name = event.name;
      state.pageEstimateDetails.contactEmail = event.contactEmail;
      state.pageEstimateDetails.contactPhone = event.contactPhone;

      state.pageEstimateDetails.status.activeStep = 1;
      state.pageEstimateDetails.status.clientInitalView = false;
      state.pageEstimateDetails.status.notifications.client = false;
      state.pageEstimateDetails.status.notifications.tenant = false;
      state.pageEstimateDetails.status.sentToClientForReview = false;
      state.pageEstimateDetails.status.stage = 'PENDING';

      state.pageEstimateDetails.requestList = event.requestList;
      state.pageEstimateDetails.dateRequested = event.dateRequested;
      state.pageEstimateDetails.dateScheduled = event.dateScheduled;
    },

    setTodoTodayClients(state, action) {
      const newEvent = action.payload;
      state.todaysTodos = newEvent;
    },
    setCrewMembersListToState(state, action) {
      const crewListParsed = action.payload;
      state.tenantCrewMembersPage.crewMembers = [...crewListParsed];
    },

    // Logic that handles the pending service list section
    setTenantsPendingScheduleList(state, action) {
      const newEvent = action.payload;
      const pendingServiceListValues = [...newEvent];
      var servicesWithServiceDatesSet: any[] = [];
      var servicesWithWithoutServiceDatesSet: any[] = [];

      pendingServiceListValues.map((serviceDetails) => {
        if (serviceDetails.scheduleFor != null) {
          const timeAgo = new TimeAgo('en-US');
          console.log(
            'we should look to see what the profile does on load, set it before navigating to the profikle during the same estiamte flow. '
          );
          console.log('when saving the date, need to check its not the same date. ');
          const timeAgoValue = timeAgo.format(new Date(serviceDetails.scheduleFor.date));
          const service = {
            ...serviceDetails,
            lableDetails: {
              messaging: timeAgoValue,
            },
          };
          servicesWithServiceDatesSet.push(service);
        } else {
          servicesWithWithoutServiceDatesSet.push(serviceDetails);
        }
      });

      servicesWithServiceDatesSet.sort(function (a, b) {
        const dateA: any = new Date(a.scheduleFor.date);
        const dateB: any = new Date(b.scheduleFor.date);
        return dateA - dateB;
      });
      state.pendingSchedlingList = servicesWithServiceDatesSet.concat(
        servicesWithWithoutServiceDatesSet
      );
    },

    setTodaysNumbers(state, action) {
      const newEvent = action.payload;
      state.todaysDetails.projectedRevenue = newEvent.projectedRevenue;
      state.todaysDetails.totalRevenue = newEvent.totalRevenue;
    },

    setClientMessages(state, action) {
      const newEvent = action.payload;
      state.clientMessages = [...state.clientMessages, newEvent];
    },

    messagesHaveLoaded(state) {
      state.clientMessagesLoaded = true;
    },

    clientListHasLoaded(state) {
      state.clientListLoaded = true;
    },

    clientsHaveLoaded(state) {
      state.clientLoaded = true;
    },
    crewListSnapshotHasLoaded(state) {
      state.tenantCrewMembersPage.crewMembersLoaded = true;
    },

    openMessageView(state, action) {
      const { messageUid, openOrClose } = action.payload;
      const objIndex = state.clientMessages.findIndex((obj) => obj.uid === messageUid);
      state.clientMessages[objIndex].expanded = openOrClose;
    },

    openClientDetailsView(state, action) {
      const { clientUid, openOrClose } = action.payload;
      const objIndex = state.clientDetails.findIndex((obj) => obj.uid === clientUid);
      state.clientDetails[objIndex].expanded = openOrClose;
    },
    setTenantId(state, action) {
      state.tenantId = action.payload;
      state.tenantIdLoaded = true;
    },

    updateClientServiceCompleted(state, action) {
      const details = action.payload;
      const date = new Date();

      state.serviceCompletedDialog.clientUid = details.clientDetails.UID;
      state.serviceCompletedDialog.clientName = details.clientDetails.name;
      state.serviceCompletedDialog.contactPhone = details.clientDetails.contactPhone;
      state.serviceCompletedDialog.clientPrice = details.clientDetails.servicePrice;
      state.serviceCompletedDialog.clientAddress = details.clientDetails.serviceAddress;
      state.serviceCompletedDialog.scheduledService = details.clientDetails.serviceType;
      state.serviceCompletedDialog.serviceDescription = details.clientDetails.serviceDescription;
      state.serviceCompletedDialog.serviceDay = details.clientDetails.serviceDay;
      state.serviceCompletedDialog.clientServiceDate = date.toLocaleDateString(undefined, {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    },

    openServiceCompletedDialog(state) {
      state.serviceCompletedDialog.isOpen = true;
    },
    closeServiceCompletedDialog(state) {
      state.serviceCompletedDialog.isOpen = false;
      state.serviceCompletedDialog.clientName = null;
    },
    setEstiamteList(state, action) {
      const newEvent = action.payload;
      state.estimateList = newEvent;
      state.estimateListLoaded = true;
    },
    setEstiamtePageClientDetails(state, action) {
      state.newEstimateForm.clientDetails = action.payload;
    },

    updateClientInvoice(state, action) {
      const newEvent = action.payload;
      state.clientOpenProfile.invoice = [...state.clientOpenProfile.invoice, newEvent];
    },
    setNotifyClientDialog(state, action) {
      const newEvent = action.payload;
      state.clientContactDialogState.isOpen = newEvent.isOpen;
      state.clientContactDialogState.email = newEvent.email;
      state.clientContactDialogState.name = newEvent.name;
      state.clientContactDialogState.message = newEvent.message;
    },
    setNotifyClientDialogToFalse(state) {
      state.clientContactDialogState.isOpen = false;
    },
    setNotifyClientDialogClear(state) {
      state.clientContactDialogState.email = '';
      state.clientContactDialogState.name = '';
      state.clientContactDialogState.message = '';
    },
    setLoadingNotifyButtonTrue(state) {
      state.clientContactDialogState.isNotifyButtonLoading = true;
    },

    setLoadingNotifyButtonFalse(state) {
      state.clientContactDialogState.isNotifyButtonLoading = false;
    },

    setSuccefullClientNotification(state) {
      state.clientContactDialogState.isNotifyButtonLoading = false;
      state.clientContactDialogState.notifyClientResponse = true;
    },
    setClientProfileServicesToPending(state) {
      state.clientOpenProfile.completedServiceList.map((serviceDay: any) => {
        serviceDay.sentToClient = true;
      });
    },
    onNextStep(state) {
      state.newServicePage.step += 1;
    },
    setStepValueForNewServicePage(state, action) {
      state.newServicePage.step = action.payload;
    },
    onBackStep(state) {
      state.newServicePage.step -= 1;
    },
    createServiceAddress(state, action) {
      state.clientOpenProfile.firstName = null;
      state.clientOpenProfile.lastName = null;
      state.clientOpenProfile.isActive = false;
      state.clientOpenProfile.uid = null;
      state.clientOpenProfile.invoice = [];
      state.clientOpenProfile.serviceAddress = [];
      state.newServicePage.serviceAddress = action.payload;
    },
    openNewServiceDatePageCalendar(state) {
      state.newServicePage.calendarDetails.isTodaySelected = false;
      state.newServicePage.calendarDetails.isOpen = true;
    },
    closeNewServiceDatePageCalendar(state, action) {
      state.newServicePage.calendarDetails.selectedDate = action.payload;
      state.newServicePage.calendarDetails.isOpen = false;
    },
    resetNewServiceCalendarUserSelectedToday(state) {
      state.newServicePage.calendarDetails.isTodaySelected = true;
      state.newServicePage.calendarDetails.selectedDate = new Date().toDateString();
    },
    setNewServiceDetails(state, action) {
      const details = action.payload;
      state.newServicePage.details.id = details.id;
      state.newServicePage.details.serivcePrice = details.serivcePrice;
      state.newServicePage.details.serviceDescription = details.serviceDescription;
      state.newServicePage.details.serviceType = details.serviceType;
    },
    setServiceAddressFromOpenProfile(state) {
      state.newServicePage.serviceAddress = {
        name: `${state.clientOpenProfile.firstName} ${state.clientOpenProfile.lastName} `,
        steps: false,
        avtiv: true,
        totalBalanceDue: '0.00',
        address: `${state.clientOpenProfile.billingAddress} ${state.clientOpenProfile.billingCity} ${state.clientOpenProfile.billingZip} `,
        UID: state.clientOpenProfile.uid,
      };
    },

    setNewServicePrice(state, action) {
      state.newServicePage.details.serivcePrice = action.payload;
    },
    resetNewServiceState(state) {
      state.newServicePage.dialogSettings.isOpen = false;
      state.newServicePage.step = 0; // If step 0, we're assuming that
      //We can set this dynamic
      state.newServicePage.serviceAddress = null;
      state.newServicePage.calendarDetails.isOpen = false;
      state.newServicePage.calendarDetails.selectedDate = new Date().toDateString();
      state.newServicePage.calendarDetails.isTodaySelected = true;
      state.newServicePage.details.id = null;
      state.newServicePage.details.serviceType = null;
      state.newServicePage.details.serviceDescription = null;
      state.newServicePage.details.serivcePrice = '0.0';
      state.newServicePage.details.date = null;
    },
    resetNewServiceDetails(state) {
      state.newServicePage.details.id = null;
      state.newServicePage.details.serviceType = null;
      state.newServicePage.details.serviceDescription = null;
      state.newServicePage.details.serivcePrice = '0.0';
      state.newServicePage.details.date = null;
    },
    setServiceEditDescription(state, action) {
      state.clientOpenProfile.serviceEditDialogState.serviceDescription = action.payload;
    },
    setServiceEditPrice(state, action) {
      state.clientOpenProfile.serviceEditDialogState.servicePrice = action.payload;
    },
    setServiceEditDay(state, action) {
      state.clientOpenProfile.serviceEditDialogState.serviceDay = action.payload;
    },
    setServiceEditDialogOpen(state) {
      state.clientOpenProfile.serviceEditDialogState.isOpen = true;
    },
    setServiceEditDialogClose(state) {
      state.clientOpenProfile.serviceEditDialogState.isOpen = false;
    },
    setServiceEditType(state, action) {
      state.clientOpenProfile.serviceEditDialogState.sericeType = action.payload;
    },
    setServiceEditIndex(state, action) {
      state.clientOpenProfile.serviceEditDialogState.editIndex = action.payload;
    },

    setServiceCalendarDialogOpen(state, action) {
      state.clientOpenProfile.selectServiceDateState.isOpen = true;
      state.clientOpenProfile.selectServiceDateState.serviceAddressIndex = action.payload;
    },

    setServiceCalendarDialogClose(state) {
      state.clientOpenProfile.selectServiceDateState.isOpen = false;
    },

    udpateClientServiceAddressesField(state, action) {
      state.clientOpenProfile.serviceAddress = action.payload;
    },
    setSuccessPageDialogAsOpen(state) {
      state.newServicePage.dialogSettings.isOpen = true;
    },
    setEstimateNote(state, action) {
      const noteValue = action.payload;
      state.pageEstimateDetails.zoneNoteDialog.note = noteValue;
    },
    setEstimateImageUrl(state, action) {
      const noteValue = action.payload;
      state.pageEstimateDetails.zoneNoteDialog.urlToImage = noteValue.url;
      state.pageEstimateDetails.zoneNoteDialog.firestoreBucketRef = noteValue.storageRefrence;
    },

    setEstiamteNoteButtonToLoading(state) {
      state.pageEstimateDetails.zoneNoteDialog.isSaveBtnLoading = true;
    },
    setEstiamteNoteButtonToNotLoad(state) {
      state.pageEstimateDetails.zoneNoteDialog.isSaveBtnLoading = false;
    },

    setEstimateNoteLargeImageDetails(state) {
      state.pageEstimateDetails.zoneNoteDialog.openImageDetails.isOpen = true;
    },
    clearEstimateNoteLargeImageDetails(state) {
      state.pageEstimateDetails.zoneNoteDialog.openImageDetails.isOpen = false;
      state.pageEstimateDetails.zoneNoteDialog.openImageDetails.details = null;
    },
    updateEstimateNoteLargeImageDetails(state, action) {
      state.pageEstimateDetails.zoneNoteDialog.openImageDetails.details = action.payload;
    },

    setEstimateRequestList(state, action) {
      state.pageEstimateDetails.requestList = action.payload;
    },

    setStagingCrewMemberDetailsCmpyMrk(state, action) {
      state.tenantCrewMembersPage.stagingCrewMemberDetails.companyMark = action.payload;
    },
    setTenantCrewMemberPageView(state, action) {
      state.tenantCrewMembersPage.sectionView = action.payload;
    },
    updateClientDetailsFromActivatedRoute(state, action) {
      if (state.snowEventPage != null) {
        state.snowEventPage.activatedRoutes[
          state.snowRouteDialogConfig.clientDetails.activeRouteIndex
        ].sessionData.clientDetails = action.payload;
        state.snowRouteDialogConfig.sessionData.clientDetails = action.payload;
      }
    },

    udpateBussinesService(state, action) {
      const { indexToUpdate, openOrClose } = action.payload;
      if (state.businessServices != null) {
        state.businessServices[indexToUpdate].configs.isSelectedForEstimateFromClientProfile =
          openOrClose;
      }
    },

    // Routes:
    updateRouteState(state, action) {
      state.routes = action.payload;
    },
    setRouteDialogOpenState(state, action) {
      state.routeDialog.isOpen = action.payload;
    },
    setRouteDialogLoadingState(state, action) {
      state.routeDialog.isLoading = action.payload;
    },

    updateStateForToggleServiceSelection(state, action) {
      const service = action.payload;

      if (state.selectedServices.includes(service.serviceType)) {
        // Removing the service from selectedServices
        state.selectedServices = state.selectedServices.filter((s) => s !== service.serviceType);

        // Removing the corresponding service from lineItems
        state.newEstimateForm.lineItems = state.newEstimateForm.lineItems.filter(
          (item: { serviceType: any }) => item.serviceType !== service.serviceType
        );

        // Updating the totalItems count
        state.newEstimateForm.totalItems = Math.max(state.newEstimateForm.totalItems - 1, 0);
      } else {
        // Adding service to the state
        state.selectedServices.push(service.serviceType);
        // Adding to the legacy to keep backwards
        const saveDetails = {
          id: state.newEstimateForm.totalItems + 1,
          serviceType: service.serviceType,
          serviceDate: 'NOT_SET', // MHMM Look if we should set today?
          servicePrice: '0.00',
          serviceDescription: service.serviceDescription,
        };

        state.newEstimateForm.lineItems = [...state.newEstimateForm.lineItems, saveDetails];
        state.newEstimateForm.totalItems = state.newEstimateForm.totalItems + 1;
      }
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  resetBusinessServicesState,
  udpateBussinesService,
  backdropStateTrue,
  backdropStateFalse,
  openModal,
  closeModal,
  selectEvent,
  setClientMessages,
  setClientList,
  setEstiamteList,
  setServiceAddressDetails,
  setInvoiceDetails,
  setTodaysNumbers,
  setTodoTodayClients,
  messagesHaveLoaded,
  clientListHasLoaded,
  openMessageView,
  openClientDetailsView,
  setTenantId,
  updateClientServiceCompleted,
  openServiceCompletedDialog,
  closeServiceCompletedDialog,
  clientsHaveLoaded,
  crewListSnapshotHasLoaded,
  setClientProfile,
  setEstimateDetails,
  setEstimatePageDetails,
  setEstimatePageDialogViewState,
  setEstimatePageZoneDetailsDialogViewState,
  setEstimatePageDialogServiceDateViewState,
  setEstimatePageSaveBtnDialogState,
  setEstimatePageAfterFBSave,
  updateEstimateStatusStep,
  clearClientProfile,
  clearEstimateDetails,
  setEstimateLineItem,
  removeEstimateLineItem,
  setEstimateLineItemsNull,
  setEstiamtePageClientDetails,
  updateClientInvoice,
  startLoading,
  endLoading,
  tenantHasCompletedStripeSetUp,
  setNotifyClientDialog,
  setNotifyClientDialogToFalse,
  setNotifyClientDialogClear,
  setLoadingNotifyButtonTrue,
  setLoadingNotifyButtonFalse,
  setSuccefullClientNotification,
  setClientProfileServicesToPending,
  onNextStep,
  onBackStep,
  createServiceAddress,
  openNewServiceDatePageCalendar,
  closeNewServiceDatePageCalendar,
  resetNewServiceCalendarUserSelectedToday,
  setNewServiceDetails,
  setServiceAddressFromOpenProfile,
  setNewServicePrice,
  resetNewServiceState,
  setUpdatePriceToLineEstimate,
  setDateScheduledToEstimate,
  updateEstimateComments,
  setServiceEditDescription,
  setServiceEditPrice,
  setServiceEditDay,
  setServiceEditDialogClose,
  setServiceEditDialogOpen,
  setServiceEditType,
  setClientProfileServiceAddress,
  setServiceEditIndex,
  setTenantsPendingScheduleList,
  setServiceCalendarDialogOpen,
  setCrewMembersListToState,
  setServiceCalendarDialogClose,
  udpateClientServiceAddressesField,
  setSuccessPageDialogAsOpen,
  setEstimateNote,
  setEstimateImageUrl,
  setEstiamteNoteButtonToLoading,
  setEstiamteNoteButtonToNotLoad,
  setEstimateRequestList,
  setEstimateNoteLargeImageDetails,
  updateEstimateNoteLargeImageDetails,
  clearEstimateNoteLargeImageDetails,
  setStagingCrewMemberDetailsCmpyMrk,
  setTenantCrewMemberPageView,
  setReduxStateForSFMananaSessions,
  setReduxStateForSFMananaSessionOnClick,
  setReduxStateForSFMananaSessionDialog,
  setReduxStateForSnowSnapshots,
  setReduxStateForSnowDetailsDialog,
  setReduxStateForSnowDialog,
  setReduxStateForSnowRoute,
  setReduxStateForSnowMarkersInstalled,
  setReduxStateForSnowRoutes,
  setTenantRoutes,
  updateRoutesStateWithNewValues,
  updateProjectedTotalAccumulation,
  setProjectedTotalAccumulationDialogState,
  updateProjectedEventTimeAndDateState,
  setSnowEventPageState,
  setSnowRouteDialogOpenState,
  setSnowEventRouteDialogDataState,
  setAndOpenClientRouteDetails,
  updateClientDetailsFromActivatedRoute,
  setTenantSnowEventsToState,
  updateRouteState,
  setRouteDialogOpenState,
  setRouteDialogLoadingState,
  updateStateForToggleServiceSelection,
  setStepValueForNewServicePage,
  resetNewServiceDetails,
} = slice.actions;

export function updateRouteDialogOpenState(value: boolean) {
  return async () => {
    dispatch(setRouteDialogOpenState(value));
  };
}
export function updateRouteLoadingState(value: boolean) {
  return async () => {
    dispatch(setRouteDialogLoadingState(value));
  };
}

export function resetReduxStateForTenatnBussinesSerivces() {
  return async () => {
    dispatch(resetBusinessServicesState());
  };
}

export function selectServiceToAddBoolean(payload: {
  indexToUpdate: number;
  openOrClose: boolean;
}) {
  return async () => {
    dispatch(udpateBussinesService(payload));
  };
}

export function setSnowEventsToReduxState(payload: any[] | undefined) {
  return async () => {
    dispatch(setTenantSnowEventsToState(payload));
  };
}

export function setClientDetailsToNewRouteDialog(payload: any[] | undefined) {
  return async () => {
    dispatch(updateClientDetailsFromActivatedRoute(payload));
  };
}

export function setSnowEventClientDetailsDialogData(payload: {
  isDialogOpen: boolean;
  clientDetailsIndex: number;
}) {
  return async () => {
    dispatch(setAndOpenClientRouteDetails(payload));
  };
}

export function setSnowEventRouteDialogData(payload: {
  snowEvent: SnowEventDetailsProps;
  routeIndex: number;
}) {
  return async () => {
    dispatch(setSnowEventRouteDialogDataState(payload));
  };
}

export function openSnowEventRouteDialog() {
  return async () => {
    dispatch(setSnowRouteDialogOpenState(true));
  };
}
export function closeSnowEventRouteDialog() {
  return async () => {
    dispatch(setSnowRouteDialogOpenState(false));
  };
}

export function setReduxStateForSnowEventPage(payload: SnowEventPageTypes) {
  return async () => {
    dispatch(setSnowEventPageState(payload));
  };
}

export function closeDateAndTimeDialogForProjectedTotalSnowAccumulation() {
  return async () => {
    dispatch(setProjectedTotalAccumulationDialogState(false));
  };
}
export function openDateAndTimeDialogForProjectedTotalSnowAccumulation() {
  return async () => {
    dispatch(setProjectedTotalAccumulationDialogState(true));
  };
}

export function updateReduxStateOfProjectedEventDateAndTime(payload: string | null) {
  return async () => {
    dispatch(updateProjectedEventTimeAndDateState(payload));
  };
}

export function updateReduxStateOfProjectedTotalAccumulation(payload: number) {
  return async () => {
    dispatch(updateProjectedTotalAccumulation(payload));
  };
}

export function updateRouteWithNewClientDetails(payload: any) {
  return async () => {
    dispatch(updateRoutesStateWithNewValues(payload));
  };
}

export function setTenantRoutesToReduxState(payload: any) {
  return async () => {
    dispatch(setTenantRoutes(payload));
  };
}

export function setSnowMarkersInstalled(payload: boolean) {
  return async () => {
    dispatch(setReduxStateForSnowMarkersInstalled(payload));
  };
}
export function setSnowRoute(payload: { nameValue: string; firebaseId: string }) {
  return async () => {
    dispatch(setReduxStateForSnowRoute(payload));
  };
}

export function closeSnowRouteDialog() {
  return async () => {
    dispatch(setReduxStateForSnowDialog(false));
  };
}

export function openSnowRouteDialog() {
  return async () => {
    dispatch(setReduxStateForSnowDialog(true));
  };
}

//This one updates the dialog from the details that are in the Snow Folder. This is where all the clients that sign up end up:
export function handleUpdatingReduxStateWithSnowTempDetails(payload: any) {
  return async () => {
    dispatch(setReduxStateForSnowDetailsDialog(payload));
  };
}

export function setReduxStateWithFirebaseSnowSnapshotDetails(payload: any) {
  return async () => {
    dispatch(setReduxStateForSnowSnapshots(payload));
  };
}

export function closeSaveForMananaDialog() {
  return async () => {
    dispatch(setReduxStateForSFMananaSessionDialog(false));
  };
}

export function openSaveForMananaDialog() {
  return async () => {
    dispatch(setReduxStateForSFMananaSessionDialog(true));
  };
}
export function setReduxStateWithSessionData(payload: any) {
  return async () => {
    dispatch(setReduxStateForSFMananaSessionOnClick(payload));
  };
}

export function setReduxStateWithSnowRouteDetails(payload: any[]) {
  return async () => {
    dispatch(setReduxStateForSnowRoutes(payload));
  };
}

export function setReduxStateWithFirebaseDetails(payload: any[]) {
  return async () => {
    dispatch(setReduxStateForSFMananaSessions(payload));
  };
}

export function setStagingCrewMemberCmpyMrk(payload: any) {
  return async () => {
    dispatch(setStagingCrewMemberDetailsCmpyMrk(payload));
  };
}
export function clearEstimateNoteImageDetails() {
  return async () => {
    dispatch(clearEstimateNoteLargeImageDetails());
  };
}

export function updateLargeImageDialogDetails(payload: any) {
  return async () => {
    dispatch(updateEstimateNoteLargeImageDetails(payload));
  };
}

export function openLargeImageDialog() {
  return async () => {
    dispatch(setEstimateNoteLargeImageDetails());
  };
}

export function udpateEstimateRequestList(payload: any) {
  return async () => {
    dispatch(setEstimateRequestList(payload));
  };
}

export function setEstimateNoteButtonLoadingState(booleanState: boolean) {
  if (booleanState) {
    return async () => {
      dispatch(setEstiamteNoteButtonToLoading());
    };
  } else {
    return async () => {
      dispatch(setEstiamteNoteButtonToNotLoad());
    };
  }
}

export function udpateEstimateLineItemImage(payload: any) {
  return async () => {
    dispatch(setEstimateImageUrl(payload));
  };
}

export function udpateEstimateLineItemNote(payload: any) {
  return async () => {
    dispatch(setEstimateNote(payload));
  };
}

export function updateClientServiceAddressWithNewValues(payload: any) {
  return async () => {
    dispatch(udpateClientServiceAddressesField(payload));
  };
}
export function handleServiceEditIndex(payload: number) {
  return async () => {
    dispatch(setServiceEditIndex(payload));
  };
}
export function handleUpdatingClientProfileServiceAddress(payload: any) {
  return async () => {
    dispatch(setClientProfileServiceAddress(payload));
  };
}

export function handleServiceEditType(payload: string) {
  return async () => {
    dispatch(setServiceEditType(payload));
  };
}

export function handleServiceEditDialogState(isOpen: boolean) {
  return async () => {
    if (isOpen) {
      dispatch(setServiceEditDialogOpen());
    } else {
      dispatch(setServiceEditDialogClose());
    }
  };
}

export function handleSetServiceDateDialogState(isOpen: boolean, serviceIndex: any) {
  return async () => {
    if (isOpen) {
      dispatch(setServiceCalendarDialogOpen(serviceIndex));
    } else {
      dispatch(setServiceCalendarDialogClose());
    }
  };
}
export function handleServiceEditDay(payload: string) {
  return async () => {
    dispatch(setServiceEditDay(payload));
  };
}

export function handleServiceEditPrice(payload: string) {
  return async () => {
    dispatch(setServiceEditPrice(payload));
  };
}

export function handleServiceEditDescription(payload: string) {
  return async () => {
    dispatch(setServiceEditDescription(payload));
  };
}

export function handleUpdateEstimateStatusStep() {
  return async () => {
    dispatch(updateEstimateStatusStep());
  };
}

export function handleUpdatingNewCommentDashboardView(payload: any) {
  return async () => {
    dispatch(updateEstimateComments(payload));
  };
}

export function disableBackdrop() {
  return async () => {
    dispatch(backdropStateFalse());
  };
}

export function enableBackdrop() {
  return async () => {
    dispatch(backdropStateTrue());
  };
}

export function handleUpdateNewServicePrice(price: string) {
  return async () => {
    dispatch(setNewServicePrice(price));
  };
}

export function handleClientProfileServicesToPending() {
  return async () => {
    dispatch(setClientProfileServicesToPending());
  };
}

export function handleSuccesfullClientNotification() {
  return async () => {
    dispatch(setSuccefullClientNotification());
  };
}

export function setNotifyButtonLoadingToTrue() {
  return async () => {
    dispatch(setLoadingNotifyButtonTrue());
  };
}
export function setNotifyButtonLoadingToFalse() {
  return async () => {
    dispatch(setLoadingNotifyButtonFalse());
  };
}

export function clearNotifyClientDialog() {
  return async () => {
    dispatch(setNotifyClientDialogClear());
  };
}

export function closeNotifyDetailsDialog() {
  return async () => {
    dispatch(setNotifyClientDialogToFalse());
  };
}

export function setNotifyClientDialogDetails(payload: any) {
  return async () => {
    dispatch(setNotifyClientDialog(payload));
  };
}

export function updateTenantStripeTodoState() {
  return async () => {
    dispatch(tenantHasCompletedStripeSetUp());
  };
}

export function setLoadingTrue() {
  return async () => {
    dispatch(startLoading());
  };
}

export function setLoadingFalse() {
  return async () => {
    dispatch(endLoading());
  };
}

export function updateInvoice(payload: any) {
  console.log('payload: ', payload);
  return async () => {
    dispatch(updateClientInvoice(payload));
  };
}

export function addEstimateLineItemToState(payload: any) {
  return async () => {
    dispatch(setEstimateLineItem(payload));
  };
}
export function removeEstimateLineItemFromState(payload: any) {
  return async () => {
    dispatch(removeEstimateLineItem(payload));
  };
}

export function clearEstiamteLineItems() {
  return async () => {
    dispatch(setEstimateLineItemsNull());
  };
}

export function closeCompletedServiceDialog() {
  return async () => {
    dispatch(closeServiceCompletedDialog());
  };
}

export function openCompletedServiceDialog() {
  return async () => {
    dispatch(openServiceCompletedDialog());
  };
}

export function setClientCompletedServiceDetails(payload: any) {
  return async () => {
    dispatch(updateClientServiceCompleted(payload));
  };
}

export function setTenantIdToReduxState(payload: any) {
  return async () => {
    dispatch(setTenantId(payload));
  };
}
export function setClientProfilesDetailsToReduxState(firestoreDetails: any) {
  return async () => {
    dispatch(setClientProfile(firestoreDetails));
  };
}

export function setEstimateDetailsToReduxState(firestoreDetails: any) {
  return async () => {
    dispatch(setEstimateDetails(firestoreDetails));
  };
}

export function clearClientProfileState() {
  return async () => {
    dispatch(clearClientProfile());
  };
}

export function clearEstiamtePageState() {
  return async () => {
    dispatch(clearEstimateDetails());
  };
}

export function setProfileInvoiceDetailsToReduxState(firestoreDetails: any) {
  return async () => {
    dispatch(setInvoiceDetails(firestoreDetails));
  };
}

export function setClientProfileServiceAddressesDetailsToReduxState(firestoreDetails: any) {
  return async () => {
    dispatch(setServiceAddressDetails(firestoreDetails));
  };
}
export function setClientListToReduxState(firestoreClient: any) {
  return async () => {
    dispatch(setClientList(firestoreClient));
  };
}

export function setEstiamteListToReduxState(firestoreEstiamte: any) {
  return async () => {
    dispatch(setEstiamteList(firestoreEstiamte));
  };
}

export function setClientsTodoTodayToReduxState(firestoreMessage: any) {
  return async () => {
    dispatch(setTodoTodayClients(firestoreMessage));
  };
}

export function setTodaysNumbersToState(firestoreMessage: any) {
  return async () => {
    dispatch(setTodaysNumbers(firestoreMessage));
  };
}

export function setClientMessagesToReduxState(firestoreMessage: any) {
  return async () => {
    dispatch(setClientMessages(firestoreMessage));
  };
}
export function clientMessagesHaveBeenFetched() {
  return async () => {
    dispatch(messagesHaveLoaded());
  };
}

export function clientListHasBeenFetched() {
  return async () => {
    dispatch(clientListHasLoaded());
  };
}
export function clientHaveBeenFetched() {
  return async () => {
    dispatch(clientsHaveLoaded());
  };
}

export function tenantCrewHasBeenFetched() {
  return async () => {
    dispatch(crewListSnapshotHasLoaded());
  };
}

export function setOpenMessage(messageUid: string, openOrClose: boolean) {
  return async () => {
    const payload = {
      messageUid: messageUid,
      openOrClose: openOrClose,
    };
    dispatch(openMessageView(payload));
  };
}

export function setOpenClientDetails(clientUid: string, openOrClose: boolean) {
  return async () => {
    const payload = {
      clientUid: clientUid,
      openOrClose: openOrClose,
    };
    dispatch(openClientDetailsView(payload));
  };
}

export function setEstiamteDetailsToState(firestoreDetails: any) {
  return async () => {
    try {
      dispatch(setEstimatePageDetails(firestoreDetails));
    } catch (error: any) {
      console.log('ADDD');
      //   dispatch(slice.actions.hasError(error));
    }
  };
}

export function setEstiamtePageDetailsToState(clientDetails: any) {
  return async () => {
    try {
      dispatch(setEstimatePageAfterFBSave(clientDetails));
    } catch (error: any) {
      console.log('ADDD');
      //   dispatch(slice.actions.hasError(error));
    }
  };
}
export function closeEstiamtePageDialog() {
  return async () => {
    // Setting back to the default values
    const payload = {
      isOpen: false,
      requestListIndex: null,
    };
    dispatch(setEstimatePageDialogViewState(payload));
  };
}

export function closeEstiamtePageZoneDetailsDialog() {
  return async () => {
    // Setting back to the default values
    const payload = {
      isOpen: false,
      requestListIndex: null,
    };
    dispatch(setEstimatePageZoneDetailsDialogViewState(payload));
  };
}

export function setAccountCrewMemberPage(pageView: crewMemberPageTypes) {
  return async () => {
    dispatch(setTenantCrewMemberPageView(pageView));
  };
}
export function openEstiamtePageZoneDetailsDialog(requestListIndexValue: number) {
  const payload = {
    isOpen: true,
    requestListIndex: requestListIndexValue,
  };

  return async () => {
    dispatch(setEstimatePageZoneDetailsDialogViewState(payload));
  };
}

export function openEstiamtePagePriceDialog(requestListIndexValue: number) {
  const payload = {
    isOpen: true,
    requestListIndex: requestListIndexValue,
  };

  return async () => {
    dispatch(setEstimatePageDialogViewState(payload));
  };
}

export function handleEstiamtePageServiceDateDialog(btnState: boolean) {
  return async () => {
    dispatch(setEstimatePageDialogServiceDateViewState(btnState));
  };
}

export function setEstiamtePageDialogSaveBtn(btnState: boolean) {
  return async () => {
    dispatch(setEstimatePageSaveBtnDialogState(btnState));
  };
}

export function updateEstimateLineItemPrice(price: any) {
  return async () => {
    dispatch(setUpdatePriceToLineEstimate(price)); // Update the number
  };
}

export function updateEstimateServiceDateToReduxState(selectedDateValue: any) {
  return async () => {
    dispatch(setDateScheduledToEstimate(selectedDateValue));
  };
}

export function setPendingSchedulingList(listOfPendingScheduling: any) {
  return async () => {
    dispatch(setTenantsPendingScheduleList(listOfPendingScheduling));
  };
}

export function setCrewMembersList(listOfCrewMembers: any) {
  return async () => {
    dispatch(setCrewMembersListToState(listOfCrewMembers));
  };
}

export function handleUpdatingServicePageDetails(servicePageDetails: any) {
  const serviceDetails = {
    id: servicePageDetails.clientUid,
    serivcePrice: servicePageDetails.clientPrice,
    serviceDescription: servicePageDetails.serviceDescription,
    serviceType: servicePageDetails.scheduledService,
  };
  const sericeAddressDetails = {
    UID: servicePageDetails.clientUid,
    name: servicePageDetails.clientName,
    steps: false,
    avtiv: false,
    totalBalanceDue: '0.00',
    address: servicePageDetails.serviceAddress,
  };
  return async () => {
    dispatch(createServiceAddress(sericeAddressDetails)); // Need to pass these details to update correclty.
    dispatch(closeNewServiceDatePageCalendar(servicePageDetails.clientServiceDate)); // Need to pass these details to update correclty.
  };
}

export function openSuccessDialogPage() {
  return async () => {
    dispatch(setSuccessPageDialogAsOpen()); // Need to pass these details to update correclty.
  };
}
export function replaceRouteArray(routes: routesTypes[]) {
  return async () => {
    dispatch(updateRouteState(routes)); // Need to pass these details to update correclty.
  };
}

export function toggleServiceSelection(payload: ServiceTypesAndDesc) {
  return async () => {
    dispatch(updateStateForToggleServiceSelection(payload));
  };
}

export function handleNewServiceForClientFromProfilePage(
  newServiceDetails: newServiceDetailsTypesFromProfilePage
) {
  const serviceDetails: newServiceDetailsTypesFromProfilePage = {
    id: newServiceDetails.id,
    serviceDescription: newServiceDetails.serviceDescription,
    serviceType: newServiceDetails.serviceType,
  };

  return async () => {
    dispatch(setNewServiceDetails(serviceDetails)); // Need to pass these details to update correclty.
    dispatch(setServiceAddressFromOpenProfile()); // Need to update the open profile state to the service profile state.
    dispatch(setStepValueForNewServicePage(1)); // Setting to the first page on the form. Instead of dispatching a resent and then a plus one.
  };
}

export function handleNewServicePageEditServiceButtonClicked() {
  return async () => {
    dispatch(resetNewServiceDetails());
  };
}
